*,
*::after,
*::before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
#root, #app {
  height: inherit;
}
body {
  height: 100vh;
  overflow: hidden;
}
